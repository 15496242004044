.mainContainer {
  width: 100%;
  display: flex;
  direction: row;
}

.categoriesContainer,
.categoriesContainerOpen {
  width: 100%;
  max-height: 3.6rem;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: inherit;
}

.categoriesContainerOpen {
  max-height: fit-content;
}

.moreButtonContainer {
  height: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 0.8rem 0rem 0rem 2rem;
}

.moreButtonText {
  font-weight: 600;
  font-size: 1.4rem;
  color: var(--color-main-green);
  white-space: nowrap;
}

.moreButtonIcon,
.moreButtonIconSelected {
  width: 1rem;
  height: 2rem;
  margin: 0rem 0.5rem;
  rotate: 20;
}

.moreButtonIconSelected {
  transform: scaleY(-1);
}
