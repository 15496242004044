:root {
  --color-text: #596475;
  --color-main-green: #81bc45;
  --color-page: #f3f4fb;
  --color-light-green: #d9ebc7;
  --color-secondary-grey: #838991;
  --color-page-name: #b9c3ce;
  --color-main-beet: #ff527a;
  --color-light-yellow: #fff4d7;
  --color-light-beet: #ffdfe6;
  --color-light-blue: #ddebff;
  --common-padding-inline: 20%;
  --mobile-padding-inline: 4.5%;
}

@font-face {
  font-family: "AlmoniRegular";
  src: local("AlmoniRegular"),
    url("./assets/fonts/almoni-regular/almoni-regular-aaa.eot") format("eot"),
    url("./assets/fonts/almoni-regular/almoni-regular-aaa.woff") format("woff"),
    url("./assets/fonts/almoni-regular/almoni-regular-aaa.woff2") format("woff2");
}

@font-face {
  font-family: "AlmoniBold";
  src: local("AlmoniBold"), url("./assets/fonts/almoni-bold/almoni-bold-aaa.eot") format("eot"),
    url("./assets/fonts/almoni-bold/almoni-bold-aaa.woff") format("woff"),
    url("./assets/fonts/almoni-bold/almoni-bold-aaa.woff2") format("woff2");
}

@font-face {
  font-family: "AlmoniBlack";
  src: local("AlmoniBlack"), url("./assets/fonts/almoni-black/almoni-black-aaa.eot") format("eot"),
    url("./assets/fonts/almoni-black/almoni-black-aaa.woff") format("woff"),
    url("./assets/fonts/almoni-black/almoni-black-aaa.woff2") format("woff2");
}

/* Extra large devices (large laptops and desktops, 1800px and up) */
@media only screen and (min-width: 112.5em) {
  :root {
    --common-padding-inline: 20%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 112.5em) {
  :root {
    --common-padding-inline: 17%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 62em) {
  :root {
    --common-padding-inline: 10%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 48em) {
  :root {
    --common-padding-inline: 0%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 38em) {
  :root {
    --common-padding-inline: 0%;
  }
}

/** General styling **/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "AlmoniRegular";
  line-height: 1;
}

input {
  border: none;
  caret-color: black;
}

input:focus {
  outline: none;
}

div {
  caret-color: transparent;
  cursor: inherit;
}

p {
  white-space: pre-line;
  cursor: inherit;
  caret-color: transparent;
  margin: 0rem;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #69a42e;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

button:hover {
  opacity: 0.8;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBars::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
