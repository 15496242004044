.webContainer,
.mobileContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-page);
  padding: 1rem;
  padding-inline: var(--common-padding-inline);
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.leftContainer,
.rightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.veganLogo {
  width: auto;
  height: 5rem;
  margin-inline-end: 1rem;
  margin-top: -1rem;
  cursor: pointer;
}

.mobileContainer {
  display: none;
}

.searchContainer {
  align-self: center;
  margin-inline-start: 1.5rem;
}

.getInvolvedContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.getInvolved {
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  color: #596475;
  white-space: nowrap;
}

.restaurants {
  margin-inline-start: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  color: #596475;
  white-space: nowrap;
}

.restaurants:hover {
  opacity: 0.5;
}

.aboutUs {
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  color: #596475;
  white-space: nowrap;
}
.trademark {
  font-style: normal;
  font-weight: 900;
  font-size: 1.6rem;
  color: #ff527a;
  text-decoration: underline;
  white-space: nowrap;
}

.hamburgerMenu {
  margin-inline-start: 2rem;
}

.leftContainer {
  margin-top: 1rem;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .webContainer {
    display: none;
  }

  .mobileContainer {
    display: flex;
    padding-inline: var(--mobile-padding-inline);
  }

  .getInvolvedDropDownEN {
    left: 0;
  }

  .getInvolvedDropDownHE {
    right: 0;
  }
}
